import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6deb8485 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _3877c0ef = () => interopDefault(import('../pages/email-action-handler.vue' /* webpackChunkName: "pages/email-action-handler" */))
const _a80daec4 = () => interopDefault(import('../pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _ae7edae4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _cc22b78e = () => interopDefault(import('../pages/not-validated.vue' /* webpackChunkName: "pages/not-validated" */))
const _51010d70 = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _75d0e026 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7612fd8e = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _bfa058e4 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _3c80ab2c = () => interopDefault(import('../pages/admin/certificates/index.vue' /* webpackChunkName: "pages/admin/certificates/index" */))
const _437d358e = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _41ce2112 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0263e344 = () => interopDefault(import('../pages/admin/ai/language.vue' /* webpackChunkName: "pages/admin/ai/language" */))
const _6f8e28d4 = () => interopDefault(import('../pages/admin/ai/vision.vue' /* webpackChunkName: "pages/admin/ai/vision" */))
const _79d0176c = () => interopDefault(import('../pages/admin/certificates/analytics-data/index.vue' /* webpackChunkName: "pages/admin/certificates/analytics-data/index" */))
const _5d6ebfcd = () => interopDefault(import('../pages/admin/certificates/cargar-datos/index.vue' /* webpackChunkName: "pages/admin/certificates/cargar-datos/index" */))
const _5e49cee2 = () => interopDefault(import('../pages/admin/configurations/areas/index.vue' /* webpackChunkName: "pages/admin/configurations/areas/index" */))
const _ae8fe8ba = () => interopDefault(import('../pages/admin/configurations/brand/index.vue' /* webpackChunkName: "pages/admin/configurations/brand/index" */))
const _93d5004e = () => interopDefault(import('../pages/admin/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/configurations/menu/index" */))
const _12b5093a = () => interopDefault(import('../pages/admin/configurations/modules/index.vue' /* webpackChunkName: "pages/admin/configurations/modules/index" */))
const _549e4359 = () => interopDefault(import('../pages/admin/configurations/roles/index.vue' /* webpackChunkName: "pages/admin/configurations/roles/index" */))
const _7ef45a1d = () => interopDefault(import('../pages/admin/configurations/settings/index.vue' /* webpackChunkName: "pages/admin/configurations/settings/index" */))
const _38eb476b = () => interopDefault(import('../pages/admin/configurations/urbanism/index.vue' /* webpackChunkName: "pages/admin/configurations/urbanism/index" */))
const _6fd03e38 = () => interopDefault(import('../pages/admin/configurations/users/index.vue' /* webpackChunkName: "pages/admin/configurations/users/index" */))
const _9cc7699e = () => interopDefault(import('../pages/admin/listener/attention/index.vue' /* webpackChunkName: "pages/admin/listener/attention/index" */))
const _420707ee = () => interopDefault(import('../pages/admin/listener/citizens/index.vue' /* webpackChunkName: "pages/admin/listener/citizens/index" */))
const _0a433927 = () => interopDefault(import('../pages/admin/listener/dashboard/index.vue' /* webpackChunkName: "pages/admin/listener/dashboard/index" */))
const _13fa10b0 = () => interopDefault(import('../pages/admin/listener/new.vue' /* webpackChunkName: "pages/admin/listener/new" */))
const _11336a44 = () => interopDefault(import('../pages/admin/transparency/initiatives/index.vue' /* webpackChunkName: "pages/admin/transparency/initiatives/index" */))
const _062b2bda = () => interopDefault(import('../pages/admin/transparency/map/index.vue' /* webpackChunkName: "pages/admin/transparency/map/index" */))
const _1ea3f7fe = () => interopDefault(import('../pages/admin/urbanism/obras/index.vue' /* webpackChunkName: "pages/admin/urbanism/obras/index" */))
const _76cbc1fa = () => interopDefault(import('../pages/citizen/listener/create-v1.vue' /* webpackChunkName: "pages/citizen/listener/create-v1" */))
const _76af92f8 = () => interopDefault(import('../pages/citizen/listener/create-v2.vue' /* webpackChunkName: "pages/citizen/listener/create-v2" */))
const _769363f6 = () => interopDefault(import('../pages/citizen/listener/create-v3.vue' /* webpackChunkName: "pages/citizen/listener/create-v3" */))
const _134b1357 = () => interopDefault(import('../pages/citizen/listener/list.vue' /* webpackChunkName: "pages/citizen/listener/list" */))
const _616432b3 = () => interopDefault(import('../pages/citizen/listener/map.vue' /* webpackChunkName: "pages/citizen/listener/map" */))
const _4dedd44c = () => interopDefault(import('../pages/citizen/listener/my-reports/index.vue' /* webpackChunkName: "pages/citizen/listener/my-reports/index" */))
const _3d88ab4c = () => interopDefault(import('../pages/citizen/listener/success.vue' /* webpackChunkName: "pages/citizen/listener/success" */))
const _03dccb65 = () => interopDefault(import('../pages/citizen/transparency/initiatives/index.vue' /* webpackChunkName: "pages/citizen/transparency/initiatives/index" */))
const _48de8f52 = () => interopDefault(import('../pages/citizen/transparency/map.vue' /* webpackChunkName: "pages/citizen/transparency/map" */))
const _6e8b3148 = () => interopDefault(import('../pages/citizen/urbanism/obras/index.vue' /* webpackChunkName: "pages/citizen/urbanism/obras/index" */))
const _2d788fcc = () => interopDefault(import('../pages/admin/certificates/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/certificates/configurations/menu/index" */))
const _3e1f592d = () => interopDefault(import('../pages/admin/configurations/areas/area.vue' /* webpackChunkName: "pages/admin/configurations/areas/area" */))
const _30568c7b = () => interopDefault(import('../pages/admin/configurations/modules/menu-certificates.vue' /* webpackChunkName: "pages/admin/configurations/modules/menu-certificates" */))
const _a2028fd2 = () => interopDefault(import('../pages/admin/configurations/modules/menu-transparency.vue' /* webpackChunkName: "pages/admin/configurations/modules/menu-transparency" */))
const _49b476b0 = () => interopDefault(import('../pages/admin/configurations/modules/menu-urbanism.vue' /* webpackChunkName: "pages/admin/configurations/modules/menu-urbanism" */))
const _95367afe = () => interopDefault(import('../pages/admin/configurations/urbanism/edit.vue' /* webpackChunkName: "pages/admin/configurations/urbanism/edit" */))
const _1a83097e = () => interopDefault(import('../pages/admin/listener/configurations/categories/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/categories/index" */))
const _7da33b78 = () => interopDefault(import('../pages/admin/listener/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/menu/index" */))
const _e9d151d0 = () => interopDefault(import('../pages/admin/listener/configurations/organization/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/index" */))
const _db81b5c0 = () => interopDefault(import('../pages/admin/listener/configurations/trackers/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/trackers/index" */))
const _37df8753 = () => interopDefault(import('../pages/admin/listener/socialnetworks/facebook.vue' /* webpackChunkName: "pages/admin/listener/socialnetworks/facebook" */))
const _1989ebf6 = () => interopDefault(import('../pages/admin/listener/socialnetworks/twitter.vue' /* webpackChunkName: "pages/admin/listener/socialnetworks/twitter" */))
const _4204f686 = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/index" */))
const _95519f30 = () => interopDefault(import('../pages/admin/transparency/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/menu/index" */))
const _5c69a82d = () => interopDefault(import('../pages/admin/transparency/initiatives/add.vue' /* webpackChunkName: "pages/admin/transparency/initiatives/add" */))
const _2f6610a2 = () => interopDefault(import('../pages/admin/transparency/map/add.vue' /* webpackChunkName: "pages/admin/transparency/map/add" */))
const _3eb227c1 = () => interopDefault(import('../pages/admin/urbanism/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/urbanism/configurations/menu/index" */))
const _35834e10 = () => interopDefault(import('../pages/admin/urbanism/obras/add.vue' /* webpackChunkName: "pages/admin/urbanism/obras/add" */))
const _26fa2cae = () => interopDefault(import('../pages/admin/urbanism/obras/create-neighborhood.vue' /* webpackChunkName: "pages/admin/urbanism/obras/create-neighborhood" */))
const _e8d0d640 = () => interopDefault(import('../pages/admin/listener/configurations/organization/citizenattention.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/citizenattention" */))
const _0c481737 = () => interopDefault(import('../pages/admin/listener/attention/print/_id.vue' /* webpackChunkName: "pages/admin/listener/attention/print/_id" */))
const _b913e4a8 = () => interopDefault(import('../pages/admin/listener/configurations/categories/_id/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/categories/_id/index" */))
const _a06a62b4 = () => interopDefault(import('../pages/admin/listener/configurations/organization/_secretary/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/_secretary/index" */))
const _ae59431e = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/_id/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/_id/index" */))
const _56443ff2 = () => interopDefault(import('../pages/admin/listener/configurations/categories/_id/_subid.vue' /* webpackChunkName: "pages/admin/listener/configurations/categories/_id/_subid" */))
const _7813937b = () => interopDefault(import('../pages/admin/listener/configurations/organization/_secretary/_agency/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/_secretary/_agency/index" */))
const _4799c090 = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/_id/_subid/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/_id/_subid/index" */))
const _2e7ae92f = () => interopDefault(import('../pages/admin/listener/configurations/organization/_secretary/_agency/_operator/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/_secretary/_agency/_operator/index" */))
const _1a5b03e7 = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/_id/_subid/_deepid/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/_id/_subid/_deepid/index" */))
const _3d316ffe = () => interopDefault(import('../pages/admin/configurations/roles/_id.vue' /* webpackChunkName: "pages/admin/configurations/roles/_id" */))
const _2130cc4c = () => interopDefault(import('../pages/admin/configurations/users/_id.vue' /* webpackChunkName: "pages/admin/configurations/users/_id" */))
const _097565d9 = () => interopDefault(import('../pages/admin/listener/attention/_id.vue' /* webpackChunkName: "pages/admin/listener/attention/_id" */))
const _09b52674 = () => interopDefault(import('../pages/admin/transparency/initiatives/_id.vue' /* webpackChunkName: "pages/admin/transparency/initiatives/_id" */))
const _63bc558a = () => interopDefault(import('../pages/admin/transparency/map/_id.vue' /* webpackChunkName: "pages/admin/transparency/map/_id" */))
const _5781daae = () => interopDefault(import('../pages/admin/urbanism/obras/_id.vue' /* webpackChunkName: "pages/admin/urbanism/obras/_id" */))
const _8892727c = () => interopDefault(import('../pages/citizen/listener/my-reports/_id.vue' /* webpackChunkName: "pages/citizen/listener/my-reports/_id" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _6deb8485,
    name: "contact___es"
  }, {
    path: "/email-action-handler",
    component: _3877c0ef,
    name: "email-action-handler___es"
  }, {
    path: "/forbidden",
    component: _a80daec4,
    name: "forbidden___es"
  }, {
    path: "/login",
    component: _ae7edae4,
    name: "login___es"
  }, {
    path: "/not-validated",
    component: _cc22b78e,
    name: "not-validated___es"
  }, {
    path: "/password-reset",
    component: _51010d70,
    name: "password-reset___es"
  }, {
    path: "/privacy",
    component: _75d0e026,
    name: "privacy___es"
  }, {
    path: "/profile",
    component: _7612fd8e,
    name: "profile___es"
  }, {
    path: "/register",
    component: _bfa058e4,
    name: "register___es"
  }, {
    path: "/admin/certificates",
    component: _3c80ab2c,
    name: "admin-certificates___es"
  }, {
    path: "/admin/dashboard",
    component: _437d358e,
    name: "admin-dashboard___es"
  }, {
    path: "/en/",
    component: _41ce2112,
    name: "index___en"
  }, {
    path: "/en/contact",
    component: _6deb8485,
    name: "contact___en"
  }, {
    path: "/en/email-action-handler",
    component: _3877c0ef,
    name: "email-action-handler___en"
  }, {
    path: "/en/forbidden",
    component: _a80daec4,
    name: "forbidden___en"
  }, {
    path: "/en/login",
    component: _ae7edae4,
    name: "login___en"
  }, {
    path: "/en/not-validated",
    component: _cc22b78e,
    name: "not-validated___en"
  }, {
    path: "/en/password-reset",
    component: _51010d70,
    name: "password-reset___en"
  }, {
    path: "/en/privacy",
    component: _75d0e026,
    name: "privacy___en"
  }, {
    path: "/en/profile",
    component: _7612fd8e,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _bfa058e4,
    name: "register___en"
  }, {
    path: "/admin/ai/language",
    component: _0263e344,
    name: "admin-ai-language___es"
  }, {
    path: "/admin/ai/vision",
    component: _6f8e28d4,
    name: "admin-ai-vision___es"
  }, {
    path: "/admin/certificates/analytics-data",
    component: _79d0176c,
    name: "admin-certificates-analytics-data___es"
  }, {
    path: "/admin/certificates/cargar-datos",
    component: _5d6ebfcd,
    name: "admin-certificates-cargar-datos___es"
  }, {
    path: "/admin/configurations/areas",
    component: _5e49cee2,
    name: "admin-configurations-areas___es"
  }, {
    path: "/admin/configurations/brand",
    component: _ae8fe8ba,
    name: "admin-configurations-brand___es"
  }, {
    path: "/admin/configurations/menu",
    component: _93d5004e,
    name: "admin-configurations-menu___es"
  }, {
    path: "/admin/configurations/modules",
    component: _12b5093a,
    name: "admin-configurations-modules___es"
  }, {
    path: "/admin/configurations/roles",
    component: _549e4359,
    name: "admin-configurations-roles___es"
  }, {
    path: "/admin/configurations/settings",
    component: _7ef45a1d,
    name: "admin-configurations-settings___es"
  }, {
    path: "/admin/configurations/urbanism",
    component: _38eb476b,
    name: "admin-configurations-urbanism___es"
  }, {
    path: "/admin/configurations/users",
    component: _6fd03e38,
    name: "admin-configurations-users___es"
  }, {
    path: "/admin/listener/attention",
    component: _9cc7699e,
    name: "admin-listener-attention___es"
  }, {
    path: "/admin/listener/citizens",
    component: _420707ee,
    name: "admin-listener-citizens___es"
  }, {
    path: "/admin/listener/dashboard",
    component: _0a433927,
    name: "admin-listener-dashboard___es"
  }, {
    path: "/admin/listener/new",
    component: _13fa10b0,
    name: "admin-listener-new___es"
  }, {
    path: "/admin/transparency/initiatives",
    component: _11336a44,
    name: "admin-transparency-initiatives___es"
  }, {
    path: "/admin/transparency/map",
    component: _062b2bda,
    name: "admin-transparency-map___es"
  }, {
    path: "/admin/urbanism/obras",
    component: _1ea3f7fe,
    name: "admin-urbanism-obras___es"
  }, {
    path: "/citizen/listener/create-v1",
    component: _76cbc1fa,
    name: "citizen-listener-create-v1___es"
  }, {
    path: "/citizen/listener/create-v2",
    component: _76af92f8,
    name: "citizen-listener-create-v2___es"
  }, {
    path: "/citizen/listener/create-v3",
    component: _769363f6,
    name: "citizen-listener-create-v3___es"
  }, {
    path: "/citizen/listener/list",
    component: _134b1357,
    name: "citizen-listener-list___es"
  }, {
    path: "/citizen/listener/map",
    component: _616432b3,
    name: "citizen-listener-map___es"
  }, {
    path: "/citizen/listener/my-reports",
    component: _4dedd44c,
    name: "citizen-listener-my-reports___es"
  }, {
    path: "/citizen/listener/success",
    component: _3d88ab4c,
    name: "citizen-listener-success___es"
  }, {
    path: "/citizen/transparency/initiatives",
    component: _03dccb65,
    name: "citizen-transparency-initiatives___es"
  }, {
    path: "/citizen/transparency/map",
    component: _48de8f52,
    name: "citizen-transparency-map___es"
  }, {
    path: "/citizen/urbanism/obras",
    component: _6e8b3148,
    name: "citizen-urbanism-obras___es"
  }, {
    path: "/en/admin/certificates",
    component: _3c80ab2c,
    name: "admin-certificates___en"
  }, {
    path: "/en/admin/dashboard",
    component: _437d358e,
    name: "admin-dashboard___en"
  }, {
    path: "/admin/certificates/configurations/menu",
    component: _2d788fcc,
    name: "admin-certificates-configurations-menu___es"
  }, {
    path: "/admin/configurations/areas/area",
    component: _3e1f592d,
    name: "admin-configurations-areas-area___es"
  }, {
    path: "/admin/configurations/modules/menu-certificates",
    component: _30568c7b,
    name: "admin-configurations-modules-menu-certificates___es"
  }, {
    path: "/admin/configurations/modules/menu-transparency",
    component: _a2028fd2,
    name: "admin-configurations-modules-menu-transparency___es"
  }, {
    path: "/admin/configurations/modules/menu-urbanism",
    component: _49b476b0,
    name: "admin-configurations-modules-menu-urbanism___es"
  }, {
    path: "/admin/configurations/urbanism/edit",
    component: _95367afe,
    name: "admin-configurations-urbanism-edit___es"
  }, {
    path: "/admin/listener/configurations/categories",
    component: _1a83097e,
    name: "admin-listener-configurations-categories___es"
  }, {
    path: "/admin/listener/configurations/menu",
    component: _7da33b78,
    name: "admin-listener-configurations-menu___es"
  }, {
    path: "/admin/listener/configurations/organization",
    component: _e9d151d0,
    name: "admin-listener-configurations-organization___es"
  }, {
    path: "/admin/listener/configurations/trackers",
    component: _db81b5c0,
    name: "admin-listener-configurations-trackers___es"
  }, {
    path: "/admin/listener/socialnetworks/facebook",
    component: _37df8753,
    name: "admin-listener-socialnetworks-facebook___es"
  }, {
    path: "/admin/listener/socialnetworks/twitter",
    component: _1989ebf6,
    name: "admin-listener-socialnetworks-twitter___es"
  }, {
    path: "/admin/transparency/configurations/catalog",
    component: _4204f686,
    name: "admin-transparency-configurations-catalog___es"
  }, {
    path: "/admin/transparency/configurations/menu",
    component: _95519f30,
    name: "admin-transparency-configurations-menu___es"
  }, {
    path: "/admin/transparency/initiatives/add",
    component: _5c69a82d,
    name: "admin-transparency-initiatives-add___es"
  }, {
    path: "/admin/transparency/map/add",
    component: _2f6610a2,
    name: "admin-transparency-map-add___es"
  }, {
    path: "/admin/urbanism/configurations/menu",
    component: _3eb227c1,
    name: "admin-urbanism-configurations-menu___es"
  }, {
    path: "/admin/urbanism/obras/add",
    component: _35834e10,
    name: "admin-urbanism-obras-add___es"
  }, {
    path: "/admin/urbanism/obras/create-neighborhood",
    component: _26fa2cae,
    name: "admin-urbanism-obras-create-neighborhood___es"
  }, {
    path: "/en/admin/ai/language",
    component: _0263e344,
    name: "admin-ai-language___en"
  }, {
    path: "/en/admin/ai/vision",
    component: _6f8e28d4,
    name: "admin-ai-vision___en"
  }, {
    path: "/en/admin/certificates/analytics-data",
    component: _79d0176c,
    name: "admin-certificates-analytics-data___en"
  }, {
    path: "/en/admin/certificates/cargar-datos",
    component: _5d6ebfcd,
    name: "admin-certificates-cargar-datos___en"
  }, {
    path: "/en/admin/configurations/areas",
    component: _5e49cee2,
    name: "admin-configurations-areas___en"
  }, {
    path: "/en/admin/configurations/brand",
    component: _ae8fe8ba,
    name: "admin-configurations-brand___en"
  }, {
    path: "/en/admin/configurations/menu",
    component: _93d5004e,
    name: "admin-configurations-menu___en"
  }, {
    path: "/en/admin/configurations/modules",
    component: _12b5093a,
    name: "admin-configurations-modules___en"
  }, {
    path: "/en/admin/configurations/roles",
    component: _549e4359,
    name: "admin-configurations-roles___en"
  }, {
    path: "/en/admin/configurations/settings",
    component: _7ef45a1d,
    name: "admin-configurations-settings___en"
  }, {
    path: "/en/admin/configurations/urbanism",
    component: _38eb476b,
    name: "admin-configurations-urbanism___en"
  }, {
    path: "/en/admin/configurations/users",
    component: _6fd03e38,
    name: "admin-configurations-users___en"
  }, {
    path: "/en/admin/listener/attention",
    component: _9cc7699e,
    name: "admin-listener-attention___en"
  }, {
    path: "/en/admin/listener/citizens",
    component: _420707ee,
    name: "admin-listener-citizens___en"
  }, {
    path: "/en/admin/listener/dashboard",
    component: _0a433927,
    name: "admin-listener-dashboard___en"
  }, {
    path: "/en/admin/listener/new",
    component: _13fa10b0,
    name: "admin-listener-new___en"
  }, {
    path: "/en/admin/transparency/initiatives",
    component: _11336a44,
    name: "admin-transparency-initiatives___en"
  }, {
    path: "/en/admin/transparency/map",
    component: _062b2bda,
    name: "admin-transparency-map___en"
  }, {
    path: "/en/admin/urbanism/obras",
    component: _1ea3f7fe,
    name: "admin-urbanism-obras___en"
  }, {
    path: "/en/citizen/listener/create-v1",
    component: _76cbc1fa,
    name: "citizen-listener-create-v1___en"
  }, {
    path: "/en/citizen/listener/create-v2",
    component: _76af92f8,
    name: "citizen-listener-create-v2___en"
  }, {
    path: "/en/citizen/listener/create-v3",
    component: _769363f6,
    name: "citizen-listener-create-v3___en"
  }, {
    path: "/en/citizen/listener/list",
    component: _134b1357,
    name: "citizen-listener-list___en"
  }, {
    path: "/en/citizen/listener/map",
    component: _616432b3,
    name: "citizen-listener-map___en"
  }, {
    path: "/en/citizen/listener/my-reports",
    component: _4dedd44c,
    name: "citizen-listener-my-reports___en"
  }, {
    path: "/en/citizen/listener/success",
    component: _3d88ab4c,
    name: "citizen-listener-success___en"
  }, {
    path: "/en/citizen/transparency/initiatives",
    component: _03dccb65,
    name: "citizen-transparency-initiatives___en"
  }, {
    path: "/en/citizen/transparency/map",
    component: _48de8f52,
    name: "citizen-transparency-map___en"
  }, {
    path: "/en/citizen/urbanism/obras",
    component: _6e8b3148,
    name: "citizen-urbanism-obras___en"
  }, {
    path: "/admin/listener/configurations/organization/citizenattention",
    component: _e8d0d640,
    name: "admin-listener-configurations-organization-citizenattention___es"
  }, {
    path: "/en/admin/certificates/configurations/menu",
    component: _2d788fcc,
    name: "admin-certificates-configurations-menu___en"
  }, {
    path: "/en/admin/configurations/areas/area",
    component: _3e1f592d,
    name: "admin-configurations-areas-area___en"
  }, {
    path: "/en/admin/configurations/modules/menu-certificates",
    component: _30568c7b,
    name: "admin-configurations-modules-menu-certificates___en"
  }, {
    path: "/en/admin/configurations/modules/menu-transparency",
    component: _a2028fd2,
    name: "admin-configurations-modules-menu-transparency___en"
  }, {
    path: "/en/admin/configurations/modules/menu-urbanism",
    component: _49b476b0,
    name: "admin-configurations-modules-menu-urbanism___en"
  }, {
    path: "/en/admin/configurations/urbanism/edit",
    component: _95367afe,
    name: "admin-configurations-urbanism-edit___en"
  }, {
    path: "/en/admin/listener/configurations/categories",
    component: _1a83097e,
    name: "admin-listener-configurations-categories___en"
  }, {
    path: "/en/admin/listener/configurations/menu",
    component: _7da33b78,
    name: "admin-listener-configurations-menu___en"
  }, {
    path: "/en/admin/listener/configurations/organization",
    component: _e9d151d0,
    name: "admin-listener-configurations-organization___en"
  }, {
    path: "/en/admin/listener/configurations/trackers",
    component: _db81b5c0,
    name: "admin-listener-configurations-trackers___en"
  }, {
    path: "/en/admin/listener/socialnetworks/facebook",
    component: _37df8753,
    name: "admin-listener-socialnetworks-facebook___en"
  }, {
    path: "/en/admin/listener/socialnetworks/twitter",
    component: _1989ebf6,
    name: "admin-listener-socialnetworks-twitter___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog",
    component: _4204f686,
    name: "admin-transparency-configurations-catalog___en"
  }, {
    path: "/en/admin/transparency/configurations/menu",
    component: _95519f30,
    name: "admin-transparency-configurations-menu___en"
  }, {
    path: "/en/admin/transparency/initiatives/add",
    component: _5c69a82d,
    name: "admin-transparency-initiatives-add___en"
  }, {
    path: "/en/admin/transparency/map/add",
    component: _2f6610a2,
    name: "admin-transparency-map-add___en"
  }, {
    path: "/en/admin/urbanism/configurations/menu",
    component: _3eb227c1,
    name: "admin-urbanism-configurations-menu___en"
  }, {
    path: "/en/admin/urbanism/obras/add",
    component: _35834e10,
    name: "admin-urbanism-obras-add___en"
  }, {
    path: "/en/admin/urbanism/obras/create-neighborhood",
    component: _26fa2cae,
    name: "admin-urbanism-obras-create-neighborhood___en"
  }, {
    path: "/en/admin/listener/configurations/organization/citizenattention",
    component: _e8d0d640,
    name: "admin-listener-configurations-organization-citizenattention___en"
  }, {
    path: "/en/admin/listener/attention/print/:id?",
    component: _0c481737,
    name: "admin-listener-attention-print-id___en"
  }, {
    path: "/en/admin/listener/configurations/categories/:id",
    component: _b913e4a8,
    name: "admin-listener-configurations-categories-id___en"
  }, {
    path: "/en/admin/listener/configurations/organization/:secretary",
    component: _a06a62b4,
    name: "admin-listener-configurations-organization-secretary___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog/:id",
    component: _ae59431e,
    name: "admin-transparency-configurations-catalog-id___en"
  }, {
    path: "/en/admin/listener/configurations/categories/:id/:subid",
    component: _56443ff2,
    name: "admin-listener-configurations-categories-id-subid___en"
  }, {
    path: "/en/admin/listener/configurations/organization/:secretary/:agency",
    component: _7813937b,
    name: "admin-listener-configurations-organization-secretary-agency___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog/:id/:subid",
    component: _4799c090,
    name: "admin-transparency-configurations-catalog-id-subid___en"
  }, {
    path: "/en/admin/listener/configurations/organization/:secretary/:agency/:operator",
    component: _2e7ae92f,
    name: "admin-listener-configurations-organization-secretary-agency-operator___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog/:id/:subid/:deepid",
    component: _1a5b03e7,
    name: "admin-transparency-configurations-catalog-id-subid-deepid___en"
  }, {
    path: "/admin/listener/attention/print/:id?",
    component: _0c481737,
    name: "admin-listener-attention-print-id___es"
  }, {
    path: "/admin/listener/configurations/categories/:id",
    component: _b913e4a8,
    name: "admin-listener-configurations-categories-id___es"
  }, {
    path: "/admin/listener/configurations/organization/:secretary",
    component: _a06a62b4,
    name: "admin-listener-configurations-organization-secretary___es"
  }, {
    path: "/admin/transparency/configurations/catalog/:id",
    component: _ae59431e,
    name: "admin-transparency-configurations-catalog-id___es"
  }, {
    path: "/en/admin/configurations/roles/:id",
    component: _3d316ffe,
    name: "admin-configurations-roles-id___en"
  }, {
    path: "/en/admin/configurations/users/:id",
    component: _2130cc4c,
    name: "admin-configurations-users-id___en"
  }, {
    path: "/en/admin/listener/attention/:id",
    component: _097565d9,
    name: "admin-listener-attention-id___en"
  }, {
    path: "/en/admin/transparency/initiatives/:id",
    component: _09b52674,
    name: "admin-transparency-initiatives-id___en"
  }, {
    path: "/en/admin/transparency/map/:id",
    component: _63bc558a,
    name: "admin-transparency-map-id___en"
  }, {
    path: "/en/admin/urbanism/obras/:id",
    component: _5781daae,
    name: "admin-urbanism-obras-id___en"
  }, {
    path: "/en/citizen/listener/my-reports/:id?",
    component: _8892727c,
    name: "citizen-listener-my-reports-id___en"
  }, {
    path: "/admin/listener/configurations/categories/:id/:subid",
    component: _56443ff2,
    name: "admin-listener-configurations-categories-id-subid___es"
  }, {
    path: "/admin/listener/configurations/organization/:secretary/:agency",
    component: _7813937b,
    name: "admin-listener-configurations-organization-secretary-agency___es"
  }, {
    path: "/admin/transparency/configurations/catalog/:id/:subid",
    component: _4799c090,
    name: "admin-transparency-configurations-catalog-id-subid___es"
  }, {
    path: "/admin/listener/configurations/organization/:secretary/:agency/:operator",
    component: _2e7ae92f,
    name: "admin-listener-configurations-organization-secretary-agency-operator___es"
  }, {
    path: "/admin/transparency/configurations/catalog/:id/:subid/:deepid",
    component: _1a5b03e7,
    name: "admin-transparency-configurations-catalog-id-subid-deepid___es"
  }, {
    path: "/admin/configurations/roles/:id",
    component: _3d316ffe,
    name: "admin-configurations-roles-id___es"
  }, {
    path: "/admin/configurations/users/:id",
    component: _2130cc4c,
    name: "admin-configurations-users-id___es"
  }, {
    path: "/admin/listener/attention/:id",
    component: _097565d9,
    name: "admin-listener-attention-id___es"
  }, {
    path: "/admin/transparency/initiatives/:id",
    component: _09b52674,
    name: "admin-transparency-initiatives-id___es"
  }, {
    path: "/admin/transparency/map/:id",
    component: _63bc558a,
    name: "admin-transparency-map-id___es"
  }, {
    path: "/admin/urbanism/obras/:id",
    component: _5781daae,
    name: "admin-urbanism-obras-id___es"
  }, {
    path: "/citizen/listener/my-reports/:id?",
    component: _8892727c,
    name: "citizen-listener-my-reports-id___es"
  }, {
    path: "/",
    component: _41ce2112,
    name: "index___es"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
