let client

export function setClientReport(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/reports/create', data)
  },
  update: data => {
    return client.$post('/reports/update', data)
  },
  get: id => {
    return client.$post('/reports/get/' + id)
  },
  list: data => {
    return client.$post('/reports/get', data)
  },
  myReports: data => {
    return client.$post('/reports/myreports', data)
  },
  urgent: (id, data) => {
    return client.$post('/reports/urgent/' + id, data)
  },
  rating: (id, data) => {
    return client.$post('/reports/rating/' + id, data)
  },
  isPublic: (id, data) => {
    return client.$post('/reports/isPublic/' + id, data)
  },
  isDuplicate: data => {
    return client.$post('/reports/isDuplicate', data)
  },
  requestRemoval: (id, data) => {
    return client.$post('/reports/request-removal/' + id, data)
  },
  changeSubcategory: data => {
    return client.$post('/reports/changeSubcategory', data)
  },
  deleteBySubcategory: data => {
    return client.$post('/reports/deleteBySubcategory', data)
  }
}
