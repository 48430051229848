<template>
  <v-footer height="auto" class="accent white--text">
    <v-container v-if="isHome" grid-list-md>
      <v-layout row wrap justify-space-between>
        <v-flex xs12 md2>
          <nuxt-link to="/" class="d-flex">
            <img
              :src="$store.state.brand.brand.logo"
              height="48px"
              alt="Logo"
              class="text-xs-center"
              style="flex:none !important"
            />
          </nuxt-link>
          <ul class="pl-0 mt-3 subheading">
            <li>
              <a href="#">{{ $t('terms') }}</a>
            </li>
            <li>
              <a href="#">{{ $t('privacy') }}</a>
            </li>
            <li>
              <a
                :href="
                  $store.state.brand.brand.city_url
                    ? $store.state.brand.brand.city_url
                    : '#'
                "
                target="_blank"
              >
                {{ this.$store.state.brand.brand.city_name }}
              </a>
            </li>
          </ul>
        </v-flex>
        <v-flex xs12 md4>
          <h2 class="font-weight-bold" style="font-size:19px">
            {{ $t('about') }}
          </h2>
          <p class="subheading">
            Este sitio es una prueba piloto de la UPSO, demostrando el potencial
            de certificar los títulos y certificados en la Blockchain Federal
            Argentina. Buscamos elevar los niveles de transparencia y control
            tanto de los estudiantes como de la ciudadanía en general.
          </p>
        </v-flex>
        <v-flex xs12 md3>
          <h2 class="font-weight-bold" style="font-size:19px">
            {{ $t('contact') }}
          </h2>
          <v-form class="mt-2" @submit.prevent="sendEmailContact">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="contact.name"
                  outline
                  label="Nombre *"
                  required
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="contact.email"
                  outline
                  label="Email *"
                  required
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="contact.message"
                  outline
                  rows="3"
                  label="Mensaje *"
                  required
                  hide-details
                ></v-textarea>
              </v-flex>
              <v-flex xs12 class="text-xs-right">
                <v-btn
                  :disabled="
                    $wait.any ||
                      !contact.message ||
                      !contact.email ||
                      !contact.name ||
                      !email_contact
                  "
                  outline
                  color="white"
                  class="mt-0 mr-0"
                  type="submit"
                  >Enviar</v-btn
                >
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
      </v-layout>
      <v-layout row wrap mt-3>
        <v-flex xs12 sm6 text-xs-center text-sm-left>
          <h4>
            <a
              :href="
                $store.state.brand.brand.city_url
                  ? $store.state.brand.brand.city_url
                  : '#'
              "
              target="_blank"
              class="white--text"
            >
              © {{ new Date().getFullYear() }} Gobierno de
              {{ this.$store.state.brand.brand.city_name }}
            </a>
          </h4>
        </v-flex>
        <v-flex xs12 sm6 text-xs-center text-sm-right>
          <h4>
            <a class="white--text" href="https://os.city" target="_blank">
              <i class="material-icons bottom">flash_on</i> by OS City
            </a>
          </h4>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container v-else pa-3>
      <v-layout row wrap>
        <v-flex xs12 sm6 text-xs-center text-sm-left>
          <h4>
            <a
              :href="
                $store.state.brand.brand.city_url
                  ? $store.state.brand.brand.city_url
                  : '#'
              "
              target="_blank"
              class="white--text"
            >
              © {{ new Date().getFullYear() }} Gobierno de
              {{ this.$store.state.brand.brand.city_name }}
            </a>
          </h4>
        </v-flex>
        <v-flex xs12 sm6 text-xs-center text-sm-right>
          <h4>
            <a class="white--text" href="https://os.city" target="_blank">
              <i class="material-icons bottom">flash_on</i> by OS City
            </a>
          </h4>
        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar
      v-model="snackbar.show"
      :top="true"
      :right="true"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <v-btn dark flat @click.native="snackbar.show = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-footer>
</template>

<i18n>
{
  "en": {
    "about": "About",
    "terms": "Terms of use",
    "privacy": "Notice of Privacy",
    "contact": "Contact"
  },
  "es": {
    "about": "Acerca de",
    "terms": "Términos de uso",
    "privacy": "Aviso de privacidad",
    "contact": "Contacto"
  }
}
</i18n>

<script>
import axios from 'axios'
import SettingService from '@/services/SettingService.js'

export default {
  data() {
    return {
      isHome: this.$route.path === '/',
      contact: {
        name: null,
        email: null,
        message: null
      },
      email_contact: null,
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
        right: '',
        top: ''
      },
      required: [v => !!v || 'El campo es requerido']
    }
  },
  watch: {
    $route(to) {
      this.isHome = to.path === '/'
    }
  },
  mounted() {
    SettingService.get()
      .then(res => {
        if (res.email_contact) {
          this.email_contact = res.email_contact
        }
      })
      .catch(err => {
        console.error(err)
      })
  },
  methods: {
    sendEmailContact() {
      this.$wait.start('full')
      axios
        .post('/api/send-email', {
          template_id: 'd-a5768e43c43f41268cec11d0b72f9d88',
          personalizations: [
            {
              to: this.email_contact,
              dynamic_template_data: {
                title: 'Email de contacto',
                name: this.contact.name,
                email: this.contact.email,
                message: this.contact.message,
                subject: 'Email de contacto'
              }
            }
          ]
        })
        .then(() => {
          this.contact.name = null
          this.contact.email = null
          this.contact.message = null
          this.snackbar.show = true
          this.snackbar.text =
            'Tu mensaje ha sido enviado. Gracias por contactarnos'
          this.snackbar.color = 'success'
        })
        .catch(err => {
          this.snackbar.show = true
          this.snackbar.text = err.message
          this.snackbar.color = 'error'
        })
        .finally(() => {
          this.$wait.end('full')
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.v-footer {
  z-index 4
  min-height auto
  i.material-icons.bottom {
    font-size 0.8rem
  }
  ul {
    list-style none
    li {
      a {
        color white
        text-decoration none
      }
    }
  }
  a {
    text-decoration none
  }
  >>>.theme--light.v-label,
  >>>.theme--light.v-input:not(.v-input--is-disabled) input,
  >>>.theme--light.v-input:not(.v-input--is-disabled) textarea {
    color: white
  }
}
</style>
