import Vue from 'vue'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

const environment = process.env.NODE_ENV

export default function({ app }) {
  let env = null
  if (environment === 'production') {
    if (
      app.context.env.baseUrl.includes('playground') ||
      app.context.env.baseUrl.includes('dev')
    ) {
      env = 'development'
      LogRocket.init('uvuzc4/os-city-nuxt-dev')
    } else {
      env = 'production'
      LogRocket.init('uvuzc4/os-city-nuxt-prod')
    }
    Sentry.init({
      dsn: 'https://1f107a5fb21049d4b18c91f81815e3ca@sentry.io/1509217',
      integrations: [new Integrations.Vue({ Vue, attachProps: true })],
      environment: env
    })
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL)
      })
    })
    if (app.store.state.auth && app.store.state.auth.user) {
      LogRocket.identify(app.store.state.auth.user.id, {
        id: app.store.state.auth.user.id,
        uid: app.store.state.auth.user.uid,
        first_name: app.store.state.auth.user.first_name,
        last_name: app.store.state.auth.user.last_name,
        email: app.store.state.auth.user.email,
        phone: app.store.state.auth.user.phone,
        type: app.store.state.auth.user.type,
        emailVerified: app.store.state.auth.user.emailVerified,
        credibility: app.store.state.auth.user.credibility
      })
      Sentry.configureScope(scope => {
        scope.setUser({
          id: app.store.state.auth.user.id,
          uid: app.store.state.auth.user.uid,
          first_name: app.store.state.auth.user.first_name,
          last_name: app.store.state.auth.user.last_name,
          email: app.store.state.auth.user.email,
          phone: app.store.state.auth.user.phone,
          type: app.store.state.auth.user.type,
          emailVerified: app.store.state.auth.user.emailVerified,
          credibility: app.store.state.auth.user.credibility
        })
      })
    }
  }
}
