import LandingTextService from '@/services/LandingTextService.js'

export const state = () => ({
  landingtext: {
    heroSection: {
      title: 'Certificados en Blockchain',
      subtitle: 'Certificación de Obras de Arte',
      second_subtitle: 'Primera Galería de Arte en usar esta tecnología',
      certificate_title: 'Encuentra tu certificado'
    },
    columnsSection: {
      slogan: 'Hacia un Arte Inteligente',
      title_column1: '¿Qué es blockchain?',
      title_column2: '¿Cómo lo usamos?',
      title_column3: 'Conoce más sobre la experiencia',
      info_column1:
        'Es una base de datos distribuida donde se lleva registro de cada transacción o intercambio que se realice. Lo innovador de blockchain es que el registro se encuentra distribuido y es imposible su modificación una vez que un dato ha sido incorporado.',
      info_column2:
        'Somos la primera universidad argentina en utilizar esta tecnología para la certificación de los títulos ycertificados. Utilizamos blockchain para darle autonomía a nuestros alumnos en la portabilidad de sus títulos y para asegurar que un certificado no ha sido falsificado.',
      info_column3:
        'Si estás interesado en conocer los detalles sobre cómo utilizamos blockchain contáctanos.'
    },
    reminderSection: {
      reminderTitle: 'Innovación y transparencia',
      reminderText:
        'La utilización de blockchain es un paso más en el camino hacia un arte innovador, que busca mejorar cotidianamente.'
    },
    hookSection: {
      hook_title: 'Ruth Benzacar Galería de Arte',
      hook_text: 'Promoviendo el Desarrollo Armónico del Arte'
    }
  }
})

export const mutations = {
  SET_LANDINGTEXT(state, landingtext) {
    state.landingtext.heroSection = {
      title: landingtext.title
        ? landingtext.title
        : state.landingtext.heroSection.title,
      subtitle: landingtext.subtitle
        ? landingtext.subtitle
        : state.landingtext.heroSection.subtitle,
      second_subtitle: landingtext.second_subtitle
        ? landingtext.second_subtitle
        : state.landingtext.heroSection.second_subtitle,
      certificate_title: landingtext.certificate_title
        ? landingtext.certificate_title
        : state.landingtext.heroSection.certificate_title
    }
    state.landingtext.columnsSection = {
      slogan: landingtext.slogan
        ? landingtext.slogan
        : state.landingtext.columnsSection.slogan,
      title_column1: landingtext.title_column1
        ? landingtext.title_column1
        : state.landingtext.columnsSection.title_column1,
      title_column2: landingtext.title_column2
        ? landingtext.title_column2
        : state.landingtext.columnsSection.title_column2,
      title_column3: landingtext.title_column3
        ? landingtext.title_column3
        : state.landingtext.columnsSection.title_column3,
      info_column1: landingtext.info_column1
        ? landingtext.info_column1
        : state.landingtext.columnsSection.info_column1,
      info_column2: landingtext.info_column2
        ? landingtext.info_column2
        : state.landingtext.columnsSection.info_column2,
      info_column3: landingtext.info_column3
        ? landingtext.info_column3
        : state.landingtext.columnsSection.info_column3
    }
    state.landingtext.reminderSection = {
      reminderTitle: landingtext.reminderTitle
        ? landingtext.reminderTitle
        : state.landingtext.reminderSection.reminderTitle,
      reminderText: landingtext.reminderText
        ? landingtext.reminderText
        : state.landingtext.reminderSection.reminderText
    }
    state.landingtext.hookSection = {
      hook_title: landingtext.hook_title
        ? landingtext.hook_title
        : state.landingtext.hookSection.hook_title,
      hook_text: landingtext.hook_text
        ? landingtext.hook_text
        : state.landingtext.hookSection.hook_text
    }
    if (landingtext.id) state.landingtext.id = landingtext.id
  }
}

export const actions = {
  async get({ commit }) {
    const { result } = await LandingTextService.get()
    if (result && result.length > 0) {
      commit('SET_LANDINGTEXT', result[0])
    }
  }
}
