let client

export function setClientOperator(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/operator/create', data)
  },
  update: data => {
    return client.$post('/operator/update', data)
  },
  list: () => {
    return client.$post('/operator/list/')
  },
  get: (secretary, agency, id) => {
    return client.$post('/operator/get/' + secretary + '/' + agency + '/' + id)
  },
  getByParent: (secretary, agency) => {
    return client.$post('/operator/getbyparent/' + secretary + '/' + agency)
  },
  getById: id => {
    return client.$post(`/operator/getbyid/${id}`)
  },
  listByType: typeOperator => {
    return client.$post(`/operator/listbytype/${typeOperator}`)
  },
  deleteById: id => {
    return client.$post(`/operator/deletebyid/${id}`)
  },
  delete: (secretary, agency, id) => {
    return client.$post(
      '/operator/delete/' + secretary + '/' + agency + '/' + id
    )
  },
  listTypeOperator: () => {
    return client.$post('/type-operator/list/')
  }
}
