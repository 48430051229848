<template>
  <v-navigation-drawer
    :value="value"
    :temporary="$vuetify.breakpoint.smAndDown"
    app
    dark
    class="pb-5 index-superior"
    width="300"
    disable-resize-watcher
    @input="$emit('input', $event)"
  >
    <v-list class="menu-sidebar">
      <template v-if="$auth.loggedIn">
        <v-list-tile
          v-if="$store.state.auth.loggedIn && $store.state.auth.user.first_name"
          to="/profile"
          avatar
        >
          <div class="flex-img-sidebar">
            <v-avatar size="65" class="person-sidebar" color="primary">
              <img
                v-if="$store.state.auth.user.picture"
                :src="$store.state.auth.user.picture"
                alt="Avatar"
              />
              <span
                v-else-if="$store.state.auth.user.first_name"
                class="white--text headline"
              >
                {{ $store.state.auth.user.first_name.charAt(0) }}
              </span>
            </v-avatar>
            <div>
              <span class="user-name text-xs-center">
                {{ $store.state.auth.user.first_name }}
              </span>
              <br />
              <span class="user-role text-xs-center text-capitalize">
                {{
                  $store.state.auth.user.role &&
                  $store.state.auth.user.role.name
                    ? $store.state.auth.user.role.name
                    : $store.state.auth.user.type_operator
                    ? $store.state.auth.user.type_operator[0]
                    : null
                }}
              </span>
            </div>
          </div>
        </v-list-tile>
        <!-- Dashboard -->
        <v-list-tile
          v-if="setDashboard"
          :style="{ color: '#fff' }"
          class="main-menu-item border-top-menu-item"
          to="/admin/dashboard"
          active-class="primary"
        >
          <v-list-tile-action class="align-items-center">
            <v-icon>dashboard</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="text-xs-left">
              Dashboard
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!-- Elementos de opciones de SideBar -->
        <div class="flex-elements-sidebar">
          <!-- Item del Menú de Modulos -->
          <v-list-group
            v-for="(item, i) in itemsModules"
            :key="`${i}-${item.title}`"
            :group="item.group"
            :class="{
              'arrow-none': item.title || item.svg
            }"
            no-action
          >
            <v-list-tile
              v-if="item.items.length !== 0"
              slot="activator"
              class="main-menu-item"
            >
              <v-icon v-if="item.action">
                {{ item.action }}
              </v-icon>
              <v-img
                v-if="item.svg"
                :src="require(`@/assets/svgs/${item.svg}`)"
                :contain="true"
                height="30px"
                width="30px"
                class="ml-3 mr-3"
              ></v-img>
              <v-list-tile-title class="text-xs-left">{{
                item.title
              }}</v-list-tile-title>
            </v-list-tile>
            <template v-for="(subItem, j) in item.items">
              <template
                v-if="
                  (subItem.title === 'Ciudadano' ||
                    subItem.title === 'Administrador') &&
                    $auth.user.type &&
                    $auth.user.type === 'operator'
                "
              >
                <v-list :key="`${j}-${subItem.title}`" class="subgroup">
                  <v-list-group
                    v-model="subItem.inactive"
                    :href="subItem.url"
                    :group="'listener/citizen'"
                    :class="{
                      'arrow-none': item.title || item.svg
                    }"
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-tile>
                        <v-list-tile-content
                          :class="{
                            'align-non-subitem': !(
                              subItem.action || subItem.svg
                            )
                          }"
                        >
                          <v-list-tile-title
                            :class="{
                              'title-non-subitem': !(
                                subItem.action || subItem.svg
                              )
                            }"
                            >{{ subItem.title }}</v-list-tile-title
                          >
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>

                    <v-list-tile
                      v-for="(subItemItem, j) in subItem.items"
                      :key="`${j}-${subItemItem.title}`"
                      :href="subItemItem.url"
                      :to="subItemItem.to"
                      :target="subItemItem.to ? '_self' : '_blank'"
                      exact
                    >
                      <v-list-tile-action
                        :class="{
                          'action-subitem':
                            subItemItem.action || subItemItem.svg,
                          'action-non-subitem': !(
                            subItemItem.action || subItemItem.svg
                          ),
                          primary: $route.fullPath.includes(subItemItem.to)
                            ? true
                            : false
                        }"
                      >
                        <v-icon v-if="subItemItem.action">{{
                          subItemItem.action
                        }}</v-icon>
                        <v-img
                          v-if="subItemItem.svg"
                          :src="require(`@/assets/svgs/${subItemItem.svg}`)"
                          :contain="true"
                          height="20px"
                          width="20px"
                          class="white-icon"
                        ></v-img>
                      </v-list-tile-action>
                      <v-list-tile-content
                        :class="{
                          'align-non-subitem': !(
                            subItemItem.action || subItemItem.svg
                          )
                        }"
                      >
                        <v-list-tile-title
                          :class="{
                            'title-non-subitem': !(
                              subItemItem.action || subItemItem.svg
                            )
                          }"
                        >
                          {{ subItemItem.title }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list-group>
                </v-list>
              </template>
              <template v-else>
                <v-list-tile
                  :key="`${j}-${subItem.title}`"
                  :href="subItem.url"
                  :to="subItem.to"
                  :target="subItem.to ? '_self' : '_blank'"
                  exact
                >
                  <v-list-tile-action
                    :class="{
                      'action-subitem': subItem.action || subItem.svg,
                      'action-non-subitem': !(subItem.action || subItem.svg),
                      primary: $route.fullPath.includes(subItem.to)
                        ? true
                        : false
                    }"
                  >
                    <v-icon v-if="subItem.action">{{ subItem.action }}</v-icon>
                    <v-img
                      v-if="subItem.svg"
                      :src="require(`@/assets/svgs/${subItem.svg}`)"
                      :contain="true"
                      height="20px"
                      width="20px"
                      class="white-icon"
                    ></v-img>
                  </v-list-tile-action>
                  <v-list-tile-content
                    :class="{
                      'align-non-subitem': !(subItem.action || subItem.svg)
                    }"
                  >
                    <v-list-tile-title
                      :class="{
                        'title-non-subitem': !(subItem.action || subItem.svg)
                      }"
                    >
                      {{ subItem.title }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </template>
          </v-list-group>
          <!-- Items del Menu de ItemsPublic -->
          <v-list-group
            v-for="(item, i) in setItemsPublic"
            :key="`${i}-${item.title}`"
            :group="item.group"
            :class="{
              'arrow-none': item.title || subItem.svg
            }"
            no-action
          >
            <v-list-tile slot="activator" class="main-menu-item">
              <v-icon v-if="item.action" class="ml-3 mr-3">
                {{ item.action }}
              </v-icon>
              <v-img
                v-if="item.svg"
                :src="require(`@/assets/svgs/${item.svg}`)"
                :contain="true"
                height="30px"
                width="30px"
              ></v-img>
              <v-list-tile-title class="text-xs-left">{{
                item.title
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-for="(subItem, j) in item.items"
              :key="`${j}-${subItem.title}`"
              :to="subItem.path"
              exact
            >
              <v-list-tile-action
                :class="{
                  'action-subitem': subItem.action || subItem.svg,
                  primary:
                    (subItem.path === $route.path &&
                      (subItem.action || subItem.svg)) ||
                    ($route.fullPath === subItem.path + '/' + $route.params.id
                      ? true
                      : false)
                }"
              >
                <v-icon v-if="subItem.action">{{ subItem.action }}</v-icon>
                <v-img
                  v-if="subItem.svg"
                  :src="require(`@/assets/svgs/${subItem.svg}`)"
                  :contain="true"
                  height="20px"
                  width="20px"
                ></v-img>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group>
          <!-- Items del Menu Custom -->
          <v-list-group
            v-for="(item, i) in itemsCustomMenu"
            :key="`${i}-${item.title}`"
            :group="item.group"
            :class="{
              'arrow-none': item.title || subItem.svg
            }"
            no-action
          >
            <v-list-tile slot="activator" class="main-menu-item">
              <v-icon v-if="item.action" class="ml-3 mr-3">
                {{ item.action }}
              </v-icon>
              <v-img
                v-if="item.svg"
                :src="require(`@/assets/svgs/${item.svg}`)"
                :contain="true"
                height="30px"
                width="30px"
                class="ml-3 mr-3"
              ></v-img>
              <v-list-tile-title class="text-xs-left">
                {{ item.title }}
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-for="(subItem, j) in item.items"
              :key="`${j}-${subItem.title}`"
              :href="subItem.url"
              target="_blank"
              exact
            >
              <v-list-tile-action
                :class="{
                  'action-subitem': subItem.action || subItem.svg,
                  'action-non-subitem': !(subItem.action || subItem.svg),
                  primary:
                    subItem.to === $route.path &&
                    (subItem.action || subItem.svg)
                }"
              >
                <v-icon v-if="subItem.action">{{ subItem.action }}</v-icon>
                <v-img
                  v-if="subItem.svg"
                  :src="require(`@/assets/svgs/${subItem.svg}`)"
                  :contain="true"
                  height="20px"
                  width="20px"
                  class="white-icon"
                ></v-img>
              </v-list-tile-action>
              <v-list-tile-content
                :class="{
                  'align-non-subitem': !(subItem.action || subItem.svg)
                }"
              >
                <v-list-tile-title
                  :class="{
                    'title-non-subitem': !(subItem.action || subItem.svg)
                  }"
                >
                  {{ subItem.title }}
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group>
        </div>
        <!-- Flex elements sidebar ends here -->
        <v-divider></v-divider>
      </template>
      <template v-if="!$auth.loggedIn">
        <!-- <v-list-tile to="register">
          <v-list-tile-action>
            <v-icon>open_in_new</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Registrate</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile> -->
        <v-list-tile to="login">
          <v-list-tile-action>
            <v-icon>input</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Inicia sesión</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
      <template v-if="$auth.loggedIn">
        <v-list-tile
          data-cy="close-session"
          class="header-background close-session"
          @click="onLogout"
        >
          <v-list-tile-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Cerrar sesión</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import _ from 'lodash'
import { auth } from '@/plugins/firebase'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data() {
    return {
      itemsPublic: [
        // {
        //   group: 'admin/ai',
        //   icon: 'settings_input_antenna',
        //   title: 'Inteligencia',
        //   slug: 'ai',
        //   items: [
        //     {
        //       title: 'Imagen',
        //       path: '/admin/ai/vision',
        //       icon: 'photo'
        //     },
        //     {
        //       title: 'Lenguaje',
        //       path: '/admin/ai/language',
        //       icon: 'language'
        //     }
        //   ]
        // },
        {
          group: 'admin/configurations',
          icon: 'settings',
          title: 'Configuración',
          slug: 'configurations',
          items: [
            {
              title: 'Módulos de servicio',
              path: '/admin/configurations/modules',
              icon: 'view_module'
            },
            {
              title: 'Parámetros de plataforma',
              path: '/admin/configurations/settings',
              icon: 'settings'
            },
            {
              title: 'Marca',
              path: '/admin/configurations/brand',
              icon: 'business'
            },
            {
              title: 'Niveles de administración',
              path: '/admin/configurations/roles',
              icon: 'supervisor_account'
            },
            {
              title: 'Administradores',
              path: '/admin/configurations/users',
              icon: 'person'
            },
            {
              title: 'Configurar menú',
              path: '/admin/configurations/menu',
              icon: 'menu'
            }
          ]
        }
      ]
    }
  },
  computed: {
    setDashboard() {
      const listener = _.find(this.$store.state.module.modules, [
        'slug',
        'listener'
      ])
      if (listener && listener.active) {
        // Only admins and operators can see dashboard
        return (
          _.includes(['superadmin', 'operator'], this.$auth.user.type) ||
          (this.$auth.user.type === 'user' &&
            _.includes(this.$auth.user.role.views, 'listener'))
        )
      } else {
        return false
      }
    },
    setModules() {
      if (
        this.$auth.loggedIn &&
        (this.$auth.user.type === 'superadmin' ||
          (this.$auth.user.role &&
            this.$auth.user.role.views.includes('modules')))
      ) {
        return true
      } else {
        return false
      }
    },
    /** Items Custom tienen los modulos nuevos que el admin pone en configurar menu */
    itemsCustomMenu() {
      const items = []
      _.forEach(this.$store.state.menu.menu, el => {
        const group = {
          group: el.name.toLowerCase(),
          action: !el.icon.includes('.svg') ? el.icon : null,
          svg: el.icon.includes('.svg') ? el.icon : null,
          title: el.name,
          slug: el.name.toLowerCase(),
          items: []
        }
        if (_.find(el.items, ['level', 'citizen'])) {
          if (!_.find(items, ['title', 'Ciudadano'])) {
            group.items.push({
              title: 'Ciudadano',
              level: 'citizen',
              inactive: true
            })
          }
          el.items.forEach(element => {
            if (element.level === 'citizen') {
              group.items.push({
                title: element.name,
                url: element.url,
                action: !element.icon.includes('.svg') ? element.icon : null,
                svg: element.icon.includes('.svg') ? element.icon : null
              })
            }
          })
        }
        if (_.find(el.items, ['level', 'operator'])) {
          if (!_.find(items, ['title', 'Operador'])) {
            group.items.push({
              title: 'Operador',
              level: 'operator',
              inactive: true
            })
          }
          el.items.forEach(element => {
            if (element.level === 'operator') {
              group.items.push({
                title: element.name,
                url: element.url,
                action: !element.icon.includes('.svg') ? element.icon : null,
                svg: element.icon.includes('.svg') ? element.icon : null
              })
            }
          })
        }
        if (_.find(el.items, ['level', 'administrator'])) {
          if (!_.find(items, ['title', 'Administrador'])) {
            group.items.push({
              title: 'Administrador',
              level: 'administrator',
              inactive: true
            })
          }
          el.items.forEach(element => {
            if (element.level === 'administrator') {
              group.items.push({
                title: element.name,
                url: element.url,
                action: !element.icon.includes('.svg') ? element.icon : null,
                svg: element.icon.includes('.svg') ? element.icon : null
              })
            }
          })
        }
        items.push(group)
      })
      return items
    },
    /** Items Modules tienen Certificados, Obras, Urbanismo y City Listener(Reportes)  */
    itemsModules() {
      const items = []
      _.forEach(this.$store.state.module.modules, el => {
        if (el && el.active) {
          const group = {
            group: el.group ? el.group.toLowerCase() : el.name.toLowerCase(),
            action: !el.icon.includes('.svg') ? el.icon : null,
            svg: el.icon.includes('.svg') ? el.icon : null,
            title: el.alias ? el.alias : el.name,
            slug: el.name.toLowerCase(),
            items: []
          }
          if (_.find(el.items, ['level', 'operator'])) {
            if (!_.find(items, ['title', 'Operador'])) {
              if (this.$auth.user.type && this.$auth.user.type !== 'operator') {
                group.items.push({
                  title: 'Operador',
                  inactive: true,
                  level: 'operator'
                })
              }
            }
            el.items.forEach(element => {
              if (element.level === 'operator') {
                group.items.push({
                  title: element.name,
                  action: element.icon
                    ? !element.icon.includes('.svg')
                      ? element.icon
                      : null
                    : null,
                  svg: element.icon
                    ? element.icon.includes('.svg')
                      ? element.icon
                      : null
                    : null,
                  url: element.url,
                  to: element.to,
                  level: element.level
                })
              }
            })
            if (
              this.$auth.user.type &&
              this.$auth.user.type === 'operator' &&
              this.$auth.user.type_operator &&
              this.$auth.user.type_operator.includes('ejecutor')
            ) {
              const elemstoErase = ['Twitter', 'Nuevo reporte']
              _.remove(group.items, elem => {
                return (
                  elemstoErase.includes(elem.title) && elem.level === 'operator'
                )
              })
            }
          }
          if (_.find(el.items, ['level', 'citizen'])) {
            if (!_.find(items, ['title', 'Ciudadano'])) {
              if (this.$auth.user.type && this.$auth.user.type !== 'citizen') {
                group.items.push({
                  title: 'Ciudadano',
                  inactive: true,
                  level: 'citizen',
                  items: []
                })
              }
            }
            el.items.forEach(element => {
              if (element.level === 'citizen') {
                const elToPush = {
                  title: element.name,
                  action: element.icon
                    ? !element.icon.includes('.svg')
                      ? element.icon
                      : null
                    : null,
                  svg: element.icon
                    ? element.icon.includes('.svg')
                      ? element.icon
                      : null
                    : null,
                  url: element.url,
                  to: element.to,
                  level: element.level
                }
                if (
                  this.$auth.user.type &&
                  this.$auth.user.type === 'operator'
                ) {
                  group.items.find(e => {
                    if (e.title === 'Ciudadano') e.items.push(elToPush)
                  })
                } else {
                  group.items.push(elToPush)
                }
              }
            })
          }
          if (_.find(el.items, ['level', 'administrator'])) {
            if (!_.find(items, ['title', 'Administrador'])) {
              if (this.$auth.user.type && this.$auth.user.type !== 'citizen') {
                group.items.push({
                  title: 'Administrador',
                  inactive: true,
                  level: 'administrator',
                  items: []
                })
              }
            }
            el.items.forEach(element => {
              if (element.level === 'administrator') {
                const el = {
                  title: element.name,
                  action: element.icon
                    ? !element.icon.includes('.svg')
                      ? element.icon
                      : null
                    : null,
                  svg: element.icon
                    ? element.icon.includes('.svg')
                      ? element.icon
                      : null
                    : null,
                  url: element.url,
                  to: element.to,
                  level: element.level
                }
                if (
                  this.$auth.user.type &&
                  this.$auth.user.type === 'operator'
                ) {
                  group.items.find(e => {
                    if (e.title === 'Administrador') e.items.push(el)
                  })
                } else {
                  group.items.push(el)
                }
              }
            })
          }
          items.push(group)
        }
      })
      if (
        !this.$auth.loggedIn ||
        (this.$auth.user.role && this.$auth.user.role.name === 'N/A')
      ) {
        return []
      }
      _.forEach(_.uniq(items), module => {
        if (module) {
          if (module.group === 'listener') {
            if (this.$auth.user.type === 'citizen') {
              _.remove(module.items, el => {
                return el.level !== 'citizen'
              })
            } else if (
              this.$auth.user &&
              this.$auth.user.role &&
              this.$auth.user.role.name &&
              this.$auth.user.role.name === 'Callcenter'
            ) {
              _.remove(module.items, el => {
                return el.level === 'administrator'
              })
            } else if (this.$auth.user.type === 'user') {
              if (
                !this.$auth.user.role ||
                (this.$auth.user.role &&
                  !this.$auth.user.role.views.includes(module.group))
              ) {
                _.remove(items, item => {
                  return item.group === module.group
                })
              }
            } else if (this.$auth.user.type === 'operator') {
              if (!this.$auth.user.role) {
                _.remove(module.items, el => {
                  return el.level !== 'citizen' && el.level !== 'operator'
                })
              }
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (this.$auth.user.type === 'citizen') {
              _.remove(module.items, el => {
                return el.level !== 'citizen'
              })
            } else if (
              this.$auth.user.type === 'user' ||
              this.$auth.user.type === 'operator'
            ) {
              if (this.$auth.user.role && this.$auth.user.role.name !== 'N/A') {
                if (
                  !this.$auth.user.role ||
                  (this.$auth.user.role &&
                    !this.$auth.user.role.views.includes(module.group))
                ) {
                  _.remove(items, item => {
                    return item.group === module.group
                  })
                }
              } else {
                return []
              }
            }
          }
        }
      })
      return _.uniq(items)
    },
    /** Items public tienen a Inteligencia y configuración de Plataforma en General */
    setItemsPublic() {
      const items = _.cloneDeep(
        this.itemsPublic.map(obj => {
          if (obj.icon) {
            obj.action = !obj.icon.includes('.svg') ? obj.icon : null
            obj.svg = obj.icon.includes('.svg') ? obj.icon : null
          }
          obj.items = obj.items.map(element => {
            if (element.icon) {
              element.action = !element.icon.includes('.svg')
                ? element.icon
                : null
              element.svg = element.icon.includes('.svg') ? element.icon : null
            }
            return element
          })
          return obj
        })
      )
      if (
        !this.$auth.loggedIn ||
        (this.$auth.user.role && this.$auth.user.role.name === 'N/A')
      ) {
        return []
      } else if (this.$auth.user.type === 'superadmin') {
        return items
      } else if (this.$auth.user.type === 'citizen') {
        return []
      } else if (
        (this.$auth.user.type === 'user' ||
          this.$auth.user.type === 'operator') &&
        this.$auth.user.role
      ) {
        if (!this.$auth.user.role.views.includes('ai')) {
          _.remove(items, el => {
            return el.slug === 'ai'
          })
        }
        if (!this.$auth.user.role.views.includes('configurations')) {
          _.remove(items, el => {
            return el.slug === 'configurations'
          })
        }
        return items
      } else if (
        (this.$auth.user.type === 'user' ||
          this.$auth.user.type === 'operator') &&
        !this.$auth.user.role
      ) {
        _.remove(items, el => {
          return el.slug === 'ai'
        })
        _.remove(items, el => {
          return el.slug === 'configurations'
        })
      }
      return []
    }
  },
  methods: {
    onLogout() {
      auth
        .signOut()
        .then(() => {
          this.$emit('close-drawer', false)
          this.$auth.logout()
          if (this.$route.path !== '/') {
            this.$router.push({ path: '/' })
          }
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
header-background = #0a0b0c
general-background = #1c1d23
border-background = #3a3b40
font-color = #aeaeb0
submenu-text = #797f91
icon-background = #30333d
section-background = #797f91
subsection-background = #373b46

.theme--dark.v-navigation-drawer,
.theme--dark.v-list
  background-color: general-background !important

.theme--dark.v-list .v-list__tile--link:hover
  background: rgba(255,255,255,0.04);

.header-background
  background-color: header-background

.border-top-menu-item
  border-top: solid 1px border-background

>>>.v-list__group__header
  border-top: solid 1px border-background

.index-superior
  z-index: 6;
.menu-sidebar
  height: 100%;
  position: relative;
  overflow-y: overlay;
  overflow-x: hidden;
  padding 0 !important
  /* width */
  &::-webkit-scrollbar
    background: #1C1D20;
    width: 7px;
    box-shadow: none;
    border-color: transparent;

  /* Track */
  &::-webkit-scrollbar-track
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;

  /* Handle */
  &::-webkit-scrollbar-thumb
    background: #5e6073;
    border-radius: 10px;

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover
    background: #969db3;
  >>>.v-list__group__items
    >>>.v-list__tile.v-list__tile--link
      padding: 3px 16px 3px 72px
  >>>.v-list__tile--active.primary--text
    color: white !important

>>>a.v-list__tile--link
  max-height: 100% !important
>>>.v-list__tile__content>>>.v-list__tile__action
  height: 89%;

.align-items-center
  align-items: center !important
  justify-content: center !important

.menu-sidebar >>>.v-list__tile--avatar
  height: auto !important
  flex-direction: column
  padding: 20px 0

  >>>.v-avatar
    border-style: solid
    border-width: 5px
    border-radius: 50%
    img
      height: 100%;
      width: 100%;

  >>>.v-list-tile-title
    color: font-color
.user-name
  color: #fff
  margin: 0.3rem 0
  font-size: 1.8rem
  color: #cecece;

.user-role
  color: #cecece;

.main-menu-item
  height: auto

  .primary--text
    color: font-color !important

  >>>.v-list__tile
    padding: 0.5em 4em 0.5em 0.5em
    flex-direction row
    min-height: 58px
    height: auto
    justify-content: flex-start;
 >>>.v-list__tile__content
    padding: 1em 0
    flex-direction row
    height: auto
    justify-content: flex-start;
.action-non-subitem
  display:none !important;
.align-non-subitem
  margin-left: -15%;
.title-non-subitem
  font-size: 1.1em;
  color: #9a9a9a;
.action-subitem
  align-items: center
  justify-content: center !important
  align-text: center
  padding: auto
  min-width: 42px !important
  border-radius: 7.5px;
  >>>.v-icon
    color: #FFF !important
  &.primary .v-icon
    color: white !important
  &.primary .v-image__image
    filter: brightness(5)
.white-icon
  filter: brightness(5)
.close-session
  position: fixed;
  bottom: 0px;
  width: 100%;

.close_menu
  position absolute
  top 16px
  right 16px
  cursor pointer
.person-sidebar
  border: 1.8px solid var(--v-primary-base) !important;
.flex-img-sidebar
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  align-self: flex-start;
  padding-left: 3%;
.flex-elements-sidebar
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  // margin-top: 6%;
.arrow-none
  >>>.v-list__group__header__append-icon
    display: none !important;
>>>.v-list__group__items::after {
  display none !important;
}
>>>.v-list__group
  width: 100%;
>>>.v-list__tile__action.action-subitem
  margin-left:-9%;
  margin-right: 10%;
  color: #FFF !important
.no-link
  text-decoration: none;
.link-white
  color: #FFF !important
.align-certs
  margin-left: -10px !important
.subgroup
  padding: 0px !important
</style>
