import Vue from 'vue'

import {
  getMatchedComponentsInstances,
  promisify,
  globalHandleError
} from './utils'

import NuxtError from '../layouts/error.vue'

import '../assets/style/app.styl'

import '../assets/style/main.styl'

import '../assets/style/custom-nuxt.styl'

import _6f6c098b from '../layouts/default.vue'
import _77430317 from '../layouts/empty.vue'

const layouts = { "_default": _6f6c098b,"_empty": _77430317 }

export default {
  head: {"title":"OS City","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"OS City"}],"link":[{"rel":"canonical","href":undefined},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Lato:100,300,400,700,900|Material+Icons&display=swap"},{"rel":"stylesheet","href":"https:\u002F\u002Fcartodb-libs.global.ssl.fastly.net\u002Fcartodb.js\u002Fv3\u002F3.15\u002Fthemes\u002Fcss\u002Fcartodb.css"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Funpkg.com\u002Fleaflet@1.5.1\u002Fdist\u002Fleaflet.css"}],"script":[{"src":"https:\u002F\u002Fmaps.googleapis.com\u002Fmaps\u002Fapi\u002Fjs?v=3&libraries=places&key=AIzaSyDLEgCEuS2JlNGXsbGxU8BQotnHDnTpS8A"},{"src":"https:\u002F\u002Fajax.googleapis.com\u002Fajax\u002Flibs\u002Fjquery\u002F3.3.1\u002Fjquery.min.js"},{"src":"https:\u002F\u002Fcode.highcharts.com\u002Fhighcharts.js"},{"src":"https:\u002F\u002Fcode.highcharts.com\u002Fmodules\u002Fdrilldown.js"},{"src":"https:\u002F\u002Fcode.highcharts.com\u002Fmodules\u002Fwordcloud.js"},{"src":"\u002Fjs\u002Fcartodb.js"},{"src":"https:\u002F\u002Fcdnjs.cloudflare.com\u002Fajax\u002Flibs\u002Fmoment.js\u002F2.24.0\u002Fmoment.min.js"},{"src":"https:\u002F\u002Fcdnjs.cloudflare.com\u002Fajax\u002Flibs\u002Fmoment.js\u002F2.24.0\u002Flocale\u002Fes-us.js"},{"src":"https:\u002F\u002Fcdnjs.cloudflare.com\u002Fajax\u002Flibs\u002Fmoment-timezone\u002F0.5.23\u002Fmoment-timezone-with-data.min.js"},{"src":"https:\u002F\u002Fwww.google.com\u002Frecaptcha\u002Fapi.js?hl=es-419"},{"src":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002Fstackdriver-errors-js@0.7.0\u002Fdist\u002Fstackdriver-errors-concat.min.js"},{"src":"https:\u002F\u002Funpkg.com\u002Fleaflet@1.5.1\u002Fdist\u002Fleaflet.js","integrity":"sha512-GffPMF3RvMeYyc1LWMHtK8EbPv0iNZ8\u002FoTtHPx9\u002Fcc2ILxQ+u905qIwdpULaqDkyBKgOaB57QTMg7ztg8Jm2Og==","crossorigin":""}],"style":[]},

  render (h, props) {
    if (this.nuxt.err && NuxtError) {
      const errorLayout = (NuxtError.options || NuxtError).layout
      if (errorLayout) {
        this.setLayout(
          typeof errorLayout === 'function'
            ? errorLayout.call(NuxtError, this.context)
            : errorLayout
        )
      }
    }

    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [layoutEl])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter (el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [templateEl])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [

      transitionEl
    ])
  },

  data: () => ({
    isOnline: true,

    layout: null,
    layoutName: ''
  }),

  beforeCreate () {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created () {
    // Add this.$nuxt in child instances
    Vue.prototype.$nuxt = this
    // add to window so we can listen when ready
    if (process.client) {
      window.$nuxt = this

      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
    // Add $nuxt.context
    this.context = this.$options.context
  },

  computed: {
    isOffline () {
      return !this.isOnline
    }
  },

  methods: {
    refreshOnlineStatus () {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    async refresh () {
      const pages = getMatchedComponentsInstances(this.$route)

      if (!pages.length) {
        return
      }

      const promises = pages.map((page) => {
        const p = []

        if (page.$options.fetch) {
          p.push(promisify(page.$options.fetch, this.context))
        }

        if (page.$options.asyncData) {
          p.push(
            promisify(page.$options.asyncData, this.context)
              .then((newData) => {
                for (const key in newData) {
                  Vue.set(page.$data, key, newData[key])
                }
              })
          )
        }

        return Promise.all(p)
      })
      try {
        await Promise.all(promises)
      } catch (error) {
        globalHandleError(error)
        this.error(error)
      }
    },

    setLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    }
  },
}
