let client

export function setClientLandingText(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/landingtext/create', data)
  },
  updateHero: data => {
    return client.$post('/landingtext/update-hero', data)
  },
  updateColumns: data => {
    return client.$post('/landingtext/update-columns', data)
  },
  updateReminder: data => {
    return client.$post('/landingtext/update-reminder', data)
  },
  updateHook: data => {
    return client.$post('/landingtext/update-hook', data)
  },
  get: () => {
    return client.$post('/landingtext/get')
  }
}
