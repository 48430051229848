let client

export function setClientUser(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/users/create', data)
  },
  createManually: data => {
    return client.$post('/users/create-manually', data)
  },
  update: data => {
    return client.$put('/users/update', data)
  },
  updatePicture: data => {
    return client.$put('/users/update-picture', data)
  },
  updateCredibility: data => {
    return client.$put('/users/update-credibility', data)
  },
  updateManually: data => {
    return client.$put('/users/update-manually', data)
  },
  updateRole: data => {
    return client.$post('/users/update-role', data)
  },
  updateActivated: data => {
    return client.$post('/users/update-activated', data)
  },
  updateType: data => {
    return client.$post('/users/update-type', data)
  },
  updateOrganization: data => {
    return client.$put('/users/update-organization', data)
  },
  get: id => {
    return client.$post('/users/get/' + id)
  },
  getByUid: uid => {
    return client.$post('/users/getbyuid/' + uid)
  },
  getByEmail: data => {
    return client.$post('/users/get-by-email/', data)
  },
  emailExist: data => {
    return client.$post('/users/email-exist/', data)
  },
  getByPhone: phoneNumber => {
    return client.$post('/users/get-by-phone/' + phoneNumber)
  },
  list: data => {
    return client.$post('/users/list/', data)
  },
  allUsers: () => {
    return client.$post('/users/all-users/')
  },
  all: data => {
    return client.$post('/users/all/', data)
  },
  total: () => {
    return client.$post('/users/total')
  },
  getCitizensPerPage: data => {
    return client.$post('/users/get-citizens-per-page/', data)
  }
}
