export default function(context) {
  if (!context.app.$auth.loggedIn && !isPublic(context.route.path)) {
    return context.redirect('/login')
  }
}

function isPublic(path) {
  if (
    path === '/' ||
    path === '/login' ||
    path === '/contact' ||
    path === '/privacy' ||
    path === '/register' ||
    path === '/password-reset' ||
    path === '/email-action-handler'
  ) {
    return true
  } else {
    return false
  }
}
